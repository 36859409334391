<template>
  <div class="healthIQ">
    <!-- 卡片视图区域 -->
    <el-card style="min-width: 1000px">
      <el-row>
        <el-col class="tabbar">
          <div class="">
            <el-input
              size="medium"
              class="demo-input-label"
              placeholder="请输入内容"
              maxlength="10"
              v-model="params.companyName"
              clearable
            >
            </el-input>
            <el-button
              type="primary"
              size="medium"
              class="demo-input-label"
              icon="el-icon-search"
              @click="searchClick"
              >搜索</el-button
            >
            <el-button
              type="primary"
              size="medium"
              class="demo-input-label"
              icon="el-icon-plus"
              @click="addInfo()"
              >添加</el-button
            >
          </div>
        </el-col>
      </el-row>
    </el-card>
    <div class="main">
      <el-table :data="records" border style="width: 98%" :height="tableHeight">
        <el-table-column type="index" width="100"> </el-table-column>
        <el-table-column prop="companyName" label="公司" width="180" sortable>
        </el-table-column>
        <el-table-column prop="corporateName" label="法人" width="180" sortable>
        </el-table-column>
        <el-table-column prop="linkmanName" label="部门"> </el-table-column>
        <el-table-column prop="linkmanJob" label="部长"> </el-table-column>
        <el-table-column label="操作" width="230px">
          <template slot-scope="scope">
            <el-button
              type="primary"
              icon="el-icon-edit"
              size="mini"
              @click="addInfo(scope.row)"
              >编辑</el-button
            >
            <el-button
              type="danger"
              icon="el-icon-delete"
              size="mini"
              @click="removeById(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
        <template slot="opt"> > </template>
      </el-table>

      <pagination
        :pagingParams="params"
        :total="total"
        @pagingSizeChange="handleSizeChange"
        @pagingNumChange="handleCurrentChange"
      ></pagination>
    </div>
    <!-- 修改用户的对话框 -->
    <el-dialog
      title="修改用户"
      :visible.sync="setRoleDialogVisible"
      width="50%"
      @close="editDialogClosed"
    >
      <el-form :model="saveInfoParams" ref="editFormRef" label-width="70px">
        <el-form-item label="公司">
          <el-input v-model="saveInfoParams.companyName"></el-input>
        </el-form-item>
        <el-form-item label="法人">
          <el-input v-model="saveInfoParams.corporateName"></el-input>
        </el-form-item>
        <el-form-item label="部长">
          <el-input v-model="saveInfoParams.linkmanJob"></el-input>
        </el-form-item>
        <el-form-item label="部門">
          <el-input v-model="saveInfoParams.linkmanName"></el-input>
        </el-form-item>
        <el-form-item label="手机">
          <el-input v-model="saveInfoParams.linkmanPhone"></el-input>
        </el-form-item>
        <el-form-item label="companyNo">
          <el-input v-model="saveInfoParams.companyNo"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="setRoleDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveInfo">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import pagination from "@/components/common/pagination/Index.vue";
import { deleteClick } from "../../../../utils/mixin/index.js";
export default {
  name: "AdminDemoIndex",
  components: {
    pagination,
  },
  mixins: [deleteClick],
  data() {
    return {
      tableHeight: window.innerHeight - 350,
      params: { pageNum: 1, pageSize: 5, companyName: "" },
      records: [],
      total: 0,
      isIndeterminate: false,
      saveInfoParams: {
        isDel: 0,
        companyName: "",
        companyNameEn: "",
        companyNo: "",
        corporateName: "",
        linkmanJob: "",
        linkmanName: "",
        linkmanPhone: "",
      },
      setRoleDialogVisible: false,
    };
  },

  mounted() {
    this.findList();
  },
  // watch: {
  //   params: {
  //     handler: (val, olVal) => {
  //       console.log("我变化了", val, olVal); //但是val和olVal值一样
  //     },
  //     deep: true,
  //   },
  // },

  methods: {
    onSuccess(res) {
      // 成功后文本修改为原来的导入数据
      this.importDataText = "导入数据";
      // 图标修改
      this.importDataIcon = "el-icon-upload2";
      // 将上传组件改为允许使用
      this.importDisabled = false;
      // message 弹出消息
      this.$message.success("导入成功！");
      console.log(res);
      //把接收到的res存入data的result
      this.result = res;
      this.$refs.upload.clearFiles();
    },
    async healthExcelExportFile() {
      const { companyName } = this.params;
      const res = await this.$API.healthExcelExportFile({ companyName });
      const link = document.createElement("a");
      let blob = new Blob([res], { type: "application/vnd.ms-excel" });
      link.style.display = "none";
      link.href = URL.createObjectURL(blob);
      link.download = "healthIQ.xlsx";
      document.body.appendChild(link);
      link.click();
    },
    async handle(ev) {
      console.log(ev);
      //  const res = await this.$API.healthIQExcelImport(ev);
      //  console.log(res);
    },
    setRoleDialogClosed() {},
    async saveInfo() {
      const res = await this.$API.sCompanySave(this.saveInfoParams);
      if (res.status == 200) {
        //  return this.$message.error('获取用户列表失败！')
        this.setRoleDialogVisible = false;
        this.$message.success(res.msg);
        this.params.pageNum = 1;
        this.params.companyName = "";
        this.findList();
      }
    },
    async removeById(item) {
      const { id } = item;
      let params = {
        id,
      };
      // 弹框询问用户是否删除数据
      const confirmResult = await this.$confirm(
        "此操作将永久删除该用户, 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).catch((err) => err);
      if (confirmResult !== "confirm") {
        return this.$message.info("已取消删除");
      }

      const res = await this.$API.sCompanyRemove(params);

      if (res.status !== 200) {
        return this.$message.error("删除失败！");
      }
      this.$message.success("删除成功！");
      this.findList();
    },
    addInfo(item = {}) {
      this.setRoleDialogVisible = true;
      if (item) {
        this.saveInfoParams = item;
      }
    },
    searchClick() {
      this.params.pageNum = 1;
      this.findList();
    },
    handleSizeChange(newSize) {
      this.params.pageSize = newSize;
      this.findList();
    },
    handleCurrentChange(newPage) {
      this.params.pageNum = newPage;
      this.findList();
    },
    handleCheckAllChange() {},
    async findList() {
      const res = await this.$API.sCompanyQuery(this.params);
      if (res.status == 200) {
        const { records, total } = res.data;
        this.records = records;
        this.total = +total;
        console.log(this.total);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.healthIQ {
  width: 100%;
  background: #fff;
}

.tabbar {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.el-breadcrumb {
  margin-bottom: 20px;
}
.demo-input-label {
  display: inline-block;
  width: 130px;
  margin-right: 10px;
  margin-left: 0;
}

.el-tag {
  margin: 7px;
}
</style>
