export const deleteClick = {
  methods: {
    async deleteClick(fn) {
      const confirmResult = await this.$confirm(
        "此操作将永久删除该用户, 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).catch(async (err) => err);
      if (confirmResult !== "confirm") {
        return this.$message.info("已取消删除");
      }
      fn();
    },
  },
};
